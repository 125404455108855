import { memo, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import { socialLinks } from '../../utils/constants';
import logo from 'src/assets/images/solfin-coloured-logo.svg';

const contactInfo = [
  { icon: 'fa-user', text: 'XSFinance and Investment Pvt. Ltd.' },
  { icon: 'fa-address-card', text: 'U65999HR2022PTC108105' },
  { icon: 'fa-map-marker', text: 'Sector-62, Gurgaon - 122001' },
  {
    icon: 'fa-envelope',
    text: (
      <a
        href="mailto:support@solfin.co.in"
        className="text-white text-decoration-none"
      >
        support@solfin.co.in
      </a>
    )
  },
  {
    icon: 'fa-phone',
    text: (
      <a href="tel:+919599446877" className="text-white text-decoration-none">
        +91 9599446877
      </a>
    )
  }
];

const Icon = ({ href = '', iconName = '' }) => (
  <a
    className="rounded-pill text-decoration-none d-flex align-items-center justify-content-center text-theme-green"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    <i className={`fa fa-${iconName}`}></i>
  </a>
);

const LinkList = ({ links }) => (
  <ul className="list-unstyled m-0 p-0">
    {links.map(({ to, text, onClick }, index) => (
      <li key={index}>
        <NavLink to={to} onClick={onClick || null}>
          {text}
        </NavLink>
      </li>
    ))}
  </ul>
);

const ContactInfo = ({ info }) => (
  <div className="contact-via text-white">
    {info.map(({ icon, text }, index) => (
      <p className="d-flex" key={index}>
        <span>
          <i className={`fa ${icon}`}></i>
        </span>
        <span>{text}</span>
      </p>
    ))}
  </div>
);

const FooterSection = ({ title, children }) => (
  <div className="col-xl-3 col-lg-3 col-sm-6 mt-lg-0 mt-4">
    <div className="footer-heading mt-4 text-white">
      <h6 className="text-white fw-bold">{title}</h6>
    </div>
    <div className="footer-link mt-lg-4 mt-3">{children}</div>
  </div>
);

const CookieConsent = ({ cookieValue, acceptCookies, rejectCookies }) => {
  if (cookieValue === 'accepted' || cookieValue === 'rejected') return null;

  return (
    <div className="cookie-consent" style={{ left: 0 }}>
      <h6 className="text-white fw-bold">We value your privacy</h6>
      <p className="mx-2">
        We and our partners use cookies and other tracking technologies to
        improve your experience on our website. We may store and/or access
        information on a device and process personal data, such as your IP
        address and browsing data, for personalised advertising and content,
        advertising and content measurement, audience research and services
        development. Additionally, we may utilize precise geolocation data and
        identification through device scanning. Please note that your consent
        will be valid across all our subdomains. Please refer to our{' '}
        <NavLink className="policy-link" to="/CookiePolicy">
          Cookie Policy
        </NavLink>
      </p>
      <div className="row mx-2 gap-2  justify-content-around">
        <button
          className="col-sm-5 btn text-capitalize fw-semibold rounded-5 mb-3"
          onClick={rejectCookies}
        >
          Reject
        </button>
        <button
          className="col-sm-5 btn btn-primary text-capitalize calculate-btn fw-semibold rounded-5 mb-3"
          onClick={acceptCookies}
        >
          Accept
        </button>
      </div>
    </div>
  );
};

const Footer = () => {
  const cookie = Cookies.get('solfin-cookie-consent');
  const [cookieValue, setCookieValue] = useState(cookie);

  const acceptCookies = () => {
    Cookies.set('solfin-cookie-consent', 'accepted', { expires: 365 });
    setCookieValue(Cookies.get('solfin-cookie-consent'));
  };

  const rejectCookies = () => {
    Cookies.set('solfin-cookie-consent', 'rejected', { expires: 365 });
    setCookieValue(Cookies.get('solfin-cookie-consent'));
  };

  const deletePreference = () => {
    Cookies.remove('solfin-cookie-consent');
    setCookieValue(Cookies.get('solfin-cookie-consent'));
  };

  const quickLinks = useMemo(
    () => [
      { to: '/solar-calculator', text: 'Solar Calculator' },
      { to: '/AboutUs', text: 'About Us' },
      { to: '/PrivacyPolicy', text: 'XS Finance Privacy Policy' },
      { to: '/DhanvikasPrivacyPolicy', text: 'Dhanvikas Privacy Policy' },
      { to: '/CookiePolicy', text: 'Cookie Policy' },
      { to: '#', text: 'Cookie Settings', onClick: deletePreference },
      { to: '/Refundpolicy', text: 'Refund Policy' },
      { to: '/FairPracticeCode', text: 'Fair Practice Code' },
      { to: '/GrievanceRedressalPolicy', text: 'Grievance Redressal Policy' },
      { to: '/Termsandconditions', text: 'Terms and Conditions' },
      { to: '/knowledge', text: 'Knowledge' }
    ],
    []
  );

  return (
    <footer>
      <CookieConsent
        cookieValue={cookieValue}
        acceptCookies={acceptCookies}
        rejectCookies={rejectCookies}
      />
      <div className="main-footer">
        <div className="container custom-container">
          <div className="row">
            <FooterSection title="About Solfin">
              <div className="footer-about-solfin">
                <div className="footer-logo">
                  <img src={logo} alt="Solfin" title="Solfin" />
                </div>
                <div className="footer-text text-white mt-3 mt-xl-4">
                  <p>
                    Enabling finance for all sustainable, renewable and climate
                    friendly projects throughout India.
                  </p>
                </div>
              </div>
            </FooterSection>

            {/* Quick Links */}
            <FooterSection title="Quick Links">
              <LinkList links={quickLinks} />
            </FooterSection>
            {/* Contact Info */}
            <FooterSection title="Contact">
              <ContactInfo info={contactInfo} />
            </FooterSection>

            {/* Social Media */}
            <FooterSection title="Follow Us">
              <div className="solfin-social d-flex align-items-center">
                <div className="social-links d-flex">
                  {Object.keys(socialLinks).map((item, index) => (
                    <Icon
                      key={`${item}_${index}`}
                      href={socialLinks[item]}
                      iconName={item}
                    />
                  ))}
                </div>
              </div>
            </FooterSection>
          </div>
        </div>
      </div>
      <div className="solfin-copyright">
        <div className="container custom-container">
          <div className="row">
            <div className="col-md-12 text-center">
              <span>
                Copyright &copy; <strong>Solfin</strong> 2023. All Rights
                Reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
