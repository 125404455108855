import { Navigate, useLocation } from 'react-router-dom';
import Loader from 'src/components/Loader';
import useAuthAndRedirect from 'src/Hooks/useAuth';
import { allowedPaths } from 'src/utils/constants';

const ProtectedRoute = ({ Component }) => {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuthAndRedirect(true);

  if (isLoading) {
    return <Loader />;
  }

  const allowedPath = allowedPaths.includes(location.pathname.split('?')[0]);

  if (!isAuthenticated && !allowedPath) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return <Component />;
};

export default ProtectedRoute;
